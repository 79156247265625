import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setLoading, showAlert, errorToast } from '../pageFrame/actions';
import * as actions from './actions';
import * as envelopeApi from '../../lib/api/envelope/envelope';
import * as primeApi from '../../lib/api/prime/prime';
import * as auditApi from '../../lib/api/audit/audit';
import { saveFile, printFile } from '../../lib/utils';
import { updateRecipients, updateRecipient as updateRecipientApi } from '../../lib/api/envelope/recipients';
import { IRecipientBody } from './types';
import { IRecipient } from '../../common/recipients/types';

const ActionTypes = actions.ActionTypes;

export function* handleFetch(action: ReturnType<typeof actions.fetch>) {
  try {
    yield put(setLoading(true));
    const [envelopeHistory, auditHistory] = yield all([
      call(envelopeApi.getEnvelopeHistory, action.payload),
      call(envelopeApi.getAuditHistory, action.payload),
    ]);
    yield put(actions.fetchDone(auditHistory, envelopeHistory));
  } catch (err) {
    yield put(errorToast('Failed to fetch the envelope history, please refresh!'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleResend(action: ReturnType<typeof actions.resend>) {
  try {
    const envelopeId = action.payload;
    const envelopeHistory = yield call(envelopeApi.getEnvelopeHistory, action.payload);
    yield put(actions.resendDone(envelopeId, envelopeHistory));
  } catch (err) {
    yield put(errorToast('Failed to get recipients, please refresh!'));
  }
}

export function* handleSendEmail(action: ReturnType<typeof actions.sendEmail>) {
  try {
    yield call(envelopeApi.sendEmail, action.payload.envelopeId, action.payload.createEmailConfig);
    yield put(actions.sendEmailDone());
  } catch (err) {
    yield put(
      showAlert({
        message: 'Sorry, there was a problem resending your envelope. Please try again.',
        type: 'ERROR',
      })
    );
  }
}

export function* handleEnvelopeAction(action: ReturnType<typeof actions.downloadEnvelope>) {
  const { envelopeId, fileName, print } = action.payload;
  try {
    const res = yield call(envelopeApi.downloadEnvelope, envelopeId);
    const objUrl = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
    print ? yield call(printFile, objUrl) : yield call(saveFile, objUrl, fileName);
  } catch (err) {
    yield put(errorToast('Failed to download the envelope, please refresh!'));
  }
}

export function* handleCertificateAction(action: ReturnType<typeof actions.downloadCertificate>) {
  const { envelopeId, fileName, print } = action.payload;
  try {
    const res = yield call(auditApi.downloadCertificate, envelopeId);
    const objUrl = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
    print ? yield call(printFile, objUrl) : yield call(saveFile, objUrl, fileName);
  } catch (err) {
    yield put(errorToast('Failed to download the certificate, please refresh!'));
  }
}

export function* handleUpdateRecipients(action: ReturnType<typeof actions.updateRecipients>) {
  const containsSigner = action.payload.recipients.find((r: IRecipientBody) => r.role === 'Signer');
  if (action.payload.recipients.length === 0 || !containsSigner) {
    yield put(
      showAlert({ message: 'Sorry, there was a problem updating your recipients. Please try again.', type: 'ERROR' })
    );
    yield put(actions.updateRecipientsError(true));
    return;
  }

  const { data } = yield call(updateRecipients, action.payload.recipients as IRecipient[], action.payload.envelopeId);
  if (data.statusCode >= 400) {
    const errors: string = data.errors.join(',');
    const message = errors.includes('Invalid signer status')
      ? 'It looks like this recipient has finished signing this envelope. You may need to refresh to see this update.'
      : 'Sorry, there was a problem updating your recipients. Please try again.';
    yield put(
      showAlert({
        message,
        type: 'ERROR',
      })
    );

    yield put(actions.updateRecipientsError(true));
  } else {
    yield put(showAlert({ message: 'Recipient changes saved.', type: 'SUCCESS' }));
    yield put(actions.updateRecipientsDone(action.payload.recipients));
    yield put(actions.updateRecipientsError(false));
  }
}

export function* handleFetchContacts(action: ReturnType<typeof actions.fetchContacts>) {
  try {
    const contacts: any = yield call(primeApi.getContactsForPrimeUser);
    yield put(actions.fetchContactsDone(contacts));
  } catch (e) {
    console.info('Error fetching contacts');
  }
}

export function* handleUpdateRecipient(action: ReturnType<typeof actions.updateRecipient>) {
  try {
    yield call(updateRecipientApi, action.payload.recipient, action.payload.envelopeId);
    yield put(actions.updateRecipientsDone(action.payload.updatedRecipients));
    yield put(showAlert({ message: 'Recipient changes saved.', type: 'SUCCESS' }));
    yield put(actions.updateRecipientsError(false));
  } catch (e) {
    yield put(
      showAlert({
        message: 'Sorry, there was a problem updating your recipients. Please try again',
        type: 'ERROR',
      })
    );

    yield put(actions.updateRecipientsError(true));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH, handleFetch),
    takeEvery(ActionTypes.DOWNLOAD_ENVELOPE, handleEnvelopeAction),
    takeEvery(ActionTypes.DOWNLOAD_CERTIFICATE, handleCertificateAction),
    takeEvery(ActionTypes.RESEND, handleResend),
    takeEvery(ActionTypes.SEND_EMAIL, handleSendEmail),
    takeEvery(ActionTypes.UPDATE_RECIPIENTS, handleUpdateRecipients),
    takeEvery(ActionTypes.FETCH_CONTACTS, handleFetchContacts),
    takeEvery(ActionTypes.UPDATE_RECIPIENT, handleUpdateRecipient),
  ]);
}
