/* eslint-disable import/first */
import { enableAllPlugins } from 'immer';

enableAllPlugins();
// this needs to be imported here to ensure that tokens are removed from the url
// before sending data to datdog
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { filterTokensFromPerformance } from '@digisign3-ui/utils';
// @ts-ignore
import { pdfjs } from 'react-pdf';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './index.css';
import mache from '@skyslope/mache';
import * as Sentry from '@sentry/react';
import { SecurityProvider } from '@skyslope/auth-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './app';
import HeaderContextProvider from './context/header-context';
import { env, GOOGLE_TAG_MANAGER_ID } from './lib/constants';
import configureStore from './store/index';
import TimeoutModalWrapper from './common/TimeoutModalWrapper';
import MobileAppFunctions from './components/mobileAppFunctions/MobileAppFunctions';
import { getUserManager } from './auth/oktaConfig';
import PageFrame from './containers/PageFrame';
import { LaunchDarklyProvider } from './common/launchDarkly';
import SecurityEffects from './auth/SecurityEffects';
import initSentry from './lib/sentry';

initSentry();

TagManager.initialize({
  gtmId: 'GTM-56H38PR',
  auth: GOOGLE_TAG_MANAGER_ID,
});

// Helps speed up loading a PDF
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const store = configureStore();

// @ts-ignore
const theme = createMuiTheme(mache);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Providers = (
  <Sentry.ErrorBoundary fallback="An error has occurred">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <HeaderContextProvider>
              <TimeoutModalWrapper />
              <MobileAppFunctions />
              <SecurityProvider userManager={getUserManager()}>
                <LaunchDarklyProvider env={env}>
                  <SecurityEffects />
                  <PageFrame>
                    <App />
                  </PageFrame>
                </LaunchDarklyProvider>
              </SecurityProvider>
            </HeaderContextProvider>
          </Router>
        </MuiThemeProvider>
      </Provider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

ReactDOM.render(Providers, document.getElementById('root'));
